import $ from 'jquery'
// import Lenis from '@studio-freight/lenis'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

function animateSimple(elem) {
  gsap.to(elem, {
    duration: 1,
    y: 0,
    autoAlpha: 1,
    ease: "power1.out",
    stagger: 0.5,
    delay: 0.5,
    overwrite: 'auto'
  })
}

function setupFadeInElement(elem) {
  hide(elem) // assure that the element is hidden when scrolled into view
  ScrollTrigger.create({
    trigger: elem,
    start: "top 80%",
    onEnter: function() { animateSimple(elem) }
  })
}


function animateFadeTopRight(elem) {
  gsap.to(elem, {
    duration: 1,
    autoAlpha: 1,
    x: 0,
    y: 0,
    ease: "power1.out",
    delay: 0.5,
    overwrite: 'auto'
  })
}

function setupFadeTopRightElement(elem) {
  hideUpRight(elem) // assure that the element is hidden when scrolled into view
  ScrollTrigger.create({
    trigger: elem,
    start: "top 10%",
    onEnter: function() { animateFadeTopRight(elem) }
  })
}

function animateFadeTopLeft(elem) {
  gsap.to(elem, {
    duration: 1,
    autoAlpha: 1,
    x: 0,
    y: 0,
    ease: "power1.out",
    delay: 0.5,
    overwrite: 'auto'
  })
}

function setupFadeTopLeftElement(elem) {
  hideUpLeft(elem) // assure that the element is hidden when scrolled into view
  ScrollTrigger.create({
    trigger: elem,
    start: "top 10%",
    onEnter: function() { animateFadeTopLeft(elem) }
  })
}

function animateFadeDown(elem) {
  gsap.to(elem, {
    duration: 1,
    y: 0,
    autoAlpha: 1,
    ease: "power1.out",
    stagger: 0.5,
    delay: 0.8,
    overwrite: 'auto'
  })
}

function setupFadeDownElement(elem) {
  hideDown(elem) // assure that the element is hidden when scrolled into view
  ScrollTrigger.create({
    trigger: elem,
    onEnter: function() { animateFadeDown(elem) }
  })
}

function hide(elem) {
  gsap.set(elem, {autoAlpha: 0, y: 30})
}

function hideFade(elem) {
  gsap.set(elem, {autoAlpha: 0})
}

function hideDown(elem) {
  gsap.set(elem, {autoAlpha: 0, y: -70})
}

function hideUpLeft(elem) {
  gsap.set(elem, {autoAlpha: 0, y: -20, x: -20})
}

function hideUpRight(elem) {
  gsap.set(elem, {autoAlpha: 0, y: -20, x: 20})
}

function init(){
  gsap.registerPlugin(ScrollTrigger)
  gsap.utils.toArray(".js-fadein").forEach( setupFadeInElement )
  gsap.utils.toArray(".js-fade-top-left").forEach( setupFadeTopLeftElement )
  gsap.utils.toArray(".js-fade-top-right").forEach( setupFadeTopRightElement )
  gsap.utils.toArray(".bubble").forEach( setupFadeDownElement )

  const paths = [...document.querySelectorAll('path.path-anim')];

  paths.forEach(el => {
    const svgEl = el.closest('svg');
    const pathTo = el.data('path-to');

    gsap.timeline({
        scrollTrigger: {
            trigger: svgEl,
            start: "top bottom",
            end: "bottom top",
            scrub: true
        }
    })
    .to(el, {
        ease: 'none',
        attr: { d: pathTo }
    });
  });

  

  $(window).on("load resize",function(e){
    if (window.innerWidth > 1180) {
      gsap.to(".parallaxImage", {
        yPercent: -40,
        ease: "none",
        scrollTrigger: {
          trigger: ".building-block",
          scrub: true
        }, 
      });

      const imgSlow = [...document.querySelectorAll('.js-parallax-slow')];
      imgSlow.forEach(el => {
        // console.log(el);
        gsap.to(el, {
          scrollTrigger: {
            trigger: el,
            scrub: true,
          },
          yPercent: -90,
          rotate: -25,
          ease: "none"
        });
      });

      const imgMd = [...document.querySelectorAll('.js-parallax-md')];
      imgMd.forEach(el => {
        gsap.to(el, {
          scrollTrigger: {
            trigger: el,
            scrub: true,
          },
          yPercent: -180,
          rotate: -65,
          ease: "none"
        });
      });

      const imgFast = [...document.querySelectorAll('.js-parallax-fast')];
      imgFast.forEach(el => {
        gsap.to(el, {
          scrollTrigger: {
            trigger: el,
            scrub: true,
          },
          yPercent: -270,
          rotate: 75,
          ease: "none"
        });
      });
    }
  });
  
}

export default function initGreenSock(){
  $(document).ready( init )
}
